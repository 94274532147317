<template>
  <div class="page-main-bg" id="clientsPage">
    <v-container
      :class="[
        'main-container team-container position-relative',
        { 'list-view': !gridView, 'grid-view': gridView }
      ]"
    >
      <!-------------Toolbar------------------------>
      <tool-bar
        :breadcrumbs="breadcrumbs"
        :length="10"
        :toggle-drawer="toggleDrawer"
        title="العملاء المتعثرين"
      />

      <!-------------Loader---------------->
      <Loader v-if="isPageLoading" v-bind="loaderProps" />

      <!--Defaulters are not displayed while settings is not active-->
      <NoData
        v-else-if="!userLength || !isDefaultersSettingsActive"
        v-bind="noDataConfig"
      />

      <div v-else>
        <!-------------Filter------------------------>
        <client-filter
          ref="filter"
          v-bind="{ defaultersClients: true, gridView }"
          @search="searchClients"
        />

        <!---------- Client cards ---------->
        <v-row class="mt-6" v-if="gridView">
          <v-col
            v-for="user in users"
            :key="user._id"
            :lg="3"
            :md="4"
            :sm="6"
            class="property-col relative set__hover"
            cols="12"
          >
            <ClientCard :totalCount="user" :user="user" />
          </v-col>
        </v-row>

        <!---------- Client Data Table---------->
        <v-row class="mt-6" v-else>
          <v-col class="property-col" cols="12">
            <data-table-client :headers="headers" :users="users" />
          </v-col>
        </v-row>

        <!--------Pagination------------>
        <div class="text-center mt-6 mb-5" v-show="viewPagination">
          <Pagination
            :pagination="pagination"
            @onChange="loadDefaultersClients"
          />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import ToolBar from '@/components/listing/ToolBar'
import ClientFilter from '@/components/clients/ClientFilter'
import Loader from '@/components/helper/loader.vue'
import NoData from '@/components/helper/noData.vue'
import { mapMutations, mapState } from 'vuex'
import setView from '@/mixins/setView'
import { EnvService } from '@/services'

export default {
  name: 'defaulters-clients',
  components: {
    Loader,
    ToolBar,
    ClientFilter,
    ClientCard: () => import('@/components/clients/ClientCard.vue'),
    DataTableClient: () => import('@/components/clients/DataTableClient'),
    Pagination: () => import('@/components/helper/pagination.vue'),
    NoData
  },
  mixins: [setView],
  data() {
    return {
      searchFilter: '',
      gridView: true,
      breadcrumbs: [
        {
          text: 'الشئون القانونية',
          disabled: true,
          to: { path: '/dashboard/defaulters' }
        },
        {
          disabled: true,
          text: 'العملاء المتعثرين'
        }
      ],
      users: [],
      isPageLoading: false,
      pagination: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 12,
        count: 0
      },
      cardsLoader: {
        type: 'card',
        numberOfLines: 8,
        laoderClasses: 'mx-auto'
      },
      tableLoader: {
        numberOfLines: 5,
        laoderClasses: 'my-2 shadow-border-radius pa-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      },
      headers: [
        {
          text: 'اسم العميل',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'حاله العميل', value: 'clientType' },
        { text: 'نوع العميل', value: 'environments' },
        { text: 'رقم الهوية/السجل التجاري', value: 'idNumber' },
        { text: 'رقم الجوال', value: 'phoneNumber' }
      ],
      noData: {
        title: 'لا يوجد عملاء متعثرين',
        subTitle:
          'من فضلك تأكد من تفعيل هذه الخاصية من خلال الإعدادات - العملاء و الفريق<br/>و تحديد عدد أيام السماح أو الحد الأقصى لقيمة المتأخرات',
        noDataIcon: require('@/assets/img/svgs/legal defulat icon.svg')
      }
    }
  },
  computed: {
    ...mapState('accountState', ['currentEnv']),
    userLength() {
      return this.users.length
    },
    loaderProps() {
      return this.gridView ? this.cardsLoader : this.tableLoader
    },
    viewPagination() {
      return this.pagination.pagesCount > 1
    },
    getClientQuery() {
      const searchQuery = this.searchFilter
        ? `&search=${this.searchFilter}`
        : ''
      return `pageSize=${this.pagination.pageSize}&pageNumber=${this.pagination.pageNumber}&defaulters=1${searchQuery}`
    },
    isDefaultersSettingsActive() {
      return this.currentEnv.defaulters?.active
    },
    noDataConfig() {
      return {
        noDataText: this.noData.title,
        noDataSubTitle: this.noData.subTitle,
        noDataIcon: this.noData.noDataIcon
      }
    }
  },
  methods: {
    ...mapMutations('appState', ['toggleDrawer', 'addNotification']),
    changeGridView(status) {
      this.updateSetTableView(this.$route.name, status)
      this.gridView = status
    },
    searchClients(search) {
      this.pagination.pageNumber = 1
      this.searchFilter = search
      this.loadDefaultersClients()
    },
    async loadDefaultersClients() {
      try {
        this.isPageLoading = true
        const { data } = await EnvService.getAllClients(
          this.currentEnv._id,
          this.getClientQuery
        )
        this.recalculatePagination(data ?? {})
        this.users = data?.users ?? []
      } catch {
        this.addNotification({
          text: 'خطا في تحميل العملاء المتعثرين',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },
    recalculatePagination(res) {
      this.pagination.pagesCount = Math.ceil(
        res.count / this.pagination.pageSize
      )
      this.pagination.count = res.count
    }
  },
  created() {
    this.isDefaultersSettingsActive && this.loadDefaultersClients()
  }
}
</script>
